import React from 'react';
import './footer.css';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className='footer' id='footer'>
      <React.Fragment>
        <br />
        <p>2024 - MAGNO ROSA<br />
        Informações e consultas: (21) 99819-1616</p>
      </React.Fragment>
    </footer>
  );
};

export default Footer;
