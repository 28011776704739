import React from 'react';
import './atendimento.css';
import Copacabana from "../../Assets/copacabana.webp"
import Ipanema from "../../Assets/ipanema.webp"
import Barra from "../../Assets/barra.webp"
import Teleconsulta from "../../Assets/teleconsulta.webp"

const Atendimento = () => {
    return (
        <section className='atendimento' id='atendimento'>
            <div className='title'>
                <h1>ATENDIMENTO</h1>
            </div>
            <div className="cards">
                <div className="articles">
                    <header>
                        <h1>ATENDIMENTO PRESENCIAL</h1>
                    </header>
                    <div className="articles-row">
                        <article>
                            <div className="article-wrapper">
                                <figure aria-labelledby="barra-title">
                                    <img src={Barra} alt="Barra da Tijuca" />
                                    <figcaption id="barra-title">Barra da Tijuca</figcaption>
                                </figure>
                                <div className="article-body">
                                    <h2>BARRA DA TIJUCA</h2>
                                </div>
                            </div>
                        </article>
                        <article>
                            <div className="article-wrapper">
                                <figure aria-labelledby="ipanema-title">
                                    <img src={Ipanema} alt="Ipanema" />
                                    <figcaption id="ipanema-title">Ipanema</figcaption>
                                </figure>
                                <div className="article-body">
                                    <h2>IPANEMA</h2>
                                </div>
                            </div>
                        </article>
                        <article>
                            <div className="article-wrapper">
                                <figure aria-labelledby="copacabana-title">
                                    <img src={Copacabana} alt="Copacabana" />
                                    <figcaption id="copacabana-title">Copacabana</figcaption>
                                </figure>
                                <div className="article-body">
                                    <h2>COPACABANA</h2>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <div className="cards">
                <div className="articles">
                    <header>
                        <h1>TELECONSULTA</h1>
                    </header>
                    <div className="articles-row">
                        <article>
                            <div className="article-wrapper">
                                <figure aria-labelledby="teleconsulta-title">
                                    <img src={Teleconsulta} alt="Teleconsulta em todo o Brasil" />
                                    <figcaption id="teleconsulta-title">Todo o Brasil</figcaption>
                                </figure>
                                <div className="article-body">
                                    <h2>TODO O BRASIL</h2>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Atendimento;
