import "./App.css";
import Navbar from "./Components/navbar/Navbar";
import Main from "./Components/main/Main";
import Apresentacao from "./Components/apresentacao/Apresentacao";
import Atendimento from "./Components/atendimento/Atendimento";
import especialidades from "./Components/especialidades/especialidades";
import Whatsapp from "./Components/whatsapp/Whatsapp";
import Especialidades from "./Components/especialidades/especialidades";
import Footer from "./Components/footer/footer";
import Contato from "./Components/contato/contato"
import Statement from "./Components/statement/Statement";
import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="App">
      <Navbar></Navbar>
      <Main></Main>
      <Apresentacao></Apresentacao>
      <Statement></Statement>
      <Especialidades></Especialidades>
      <Atendimento></Atendimento>
      <Whatsapp></Whatsapp>
      <Contato></Contato>
      <Footer></Footer>
    </div>
    
  );
}

export default App;
