import React from "react";
import Background from "../../Assets/main-background.webp";
import "./main.css";

const Main = () => {
    return (
        <section className="main-container" id="home">
            <div className="overlay">
                <div data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
                    <h1>Dr. Magno Rosa</h1>
                    <h2>Neurocirurgião</h2>
                    <h3>
                        Você é muito maior que seu diagnóstico
                    </h3>
                    <h4>Atendimento Humanizado. Diferenciado. Personalizado.</h4>

                </div>
            </div>
            <img src={Background} alt="Background com padrão abstrato" className="background-image" />
        </section>
    );
};

export default Main;
